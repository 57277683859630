.HomeFooter {
    background-image: url(../../../../Assets/imgs/footer-img.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: #ffffff;
    // filter: blur(8px);
    // -webkit-filter: blur(8px);

    .inner-footer {
        height: 300px;
        background: linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85));
        .h3 {
            font-size: 1.25rem;
            font-weight: normal;
        }
        .h1 {
            font-size: 2rem;
            // color: rgba(0, 0, 0, 0.48);
        } 
        .btn-container {
            .btn {
                background-color: #2953cc;
                color: #ffffff;
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
   
    @media (min-width: 768px) {
        .h1 {
            font-size: 2.5rem;
        }
    }
}