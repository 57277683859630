.IntroVideo{
    @media (max-width: 768px) { 
        padding-top: 30px; 
        background-color: aliceblue;
    }

    @media (min-width: 768px) { 
        padding-top: 80px; 
        background-color: aliceblue;
    }

    .sction-tile{
        h2{
            @media (max-width: 768px) { 
                font-size: 1.8rem;
                font-weight: 600;
            }
        
            @media (min-width: 768px) { 
                font-size: 2.7rem;
                font-weight: 600;
            }
        }
    }
    .card{
        border: 0;
        transition: all .5s ease-in-out;
        &:hover{
            transform: scale(0.98);
            cursor: pointer;  
            overflow: hidden;
        }
        .card-image{
            height: 400px;
            width: 100%;
            overflow: hidden;  

            img{
                transition: all .5s ease-in-out;
            } 
        }

        .title{
            font-size: 1.5rem;
            font-weight: 700;
        }

        .description {
            font-size: .9rem;
            font-weight: 400;
        }

        .btn{
            border-radius: 50px;
            font-size: .9rem;
            padding: 10px 40px; 
    
        }
    }
}