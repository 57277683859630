.footer-credit{
    &.dark-copyright-footer-section {
        background-color: #000;
        color: #ffffff;
        font-size: .8rem;
        font-weight: normal;

        a {
            color: #ffffff;
        }
    }
}