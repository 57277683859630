.HomeIntroTop {
    background-color: #fdfdfd;
    
    .blue-text {
        color: #2c53cc;
    }

    // Header 
    .intro-header {
        color: rgb(0, 0, 0);
        font-weight: bold;
        font-style: normal;
        letter-spacing: .1px; 
    
        @media (max-width: 768px) { 
            font-size: 1rem;
            padding-top: 30px;
        }

        @media (min-width: 768px) { 
            font-size: 1.5rem;
            padding-top: 80px;
        }
    }
    
    .future-of-eBIKe {
        font-weight: bold;
        color: rgb(0, 0, 0);
        font-weight: bold;
        font-style: normal;

        @media (max-width: 768px) { 
            font-size: 1.7rem; 
        }
   
       @media (min-width: 768px) { 
            font-size: 3.5rem;
        }
    }


    .side-header {
        .text-column {
            font-weight: 700;
            font-size: 1.7rem;
        }
   } 
} 