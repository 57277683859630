.Campaign {
    font-family: "Oswald", sans-serif;
    background-color: #fdfdfd;
    
    .blue-text {
        color: #2c53cc;
    }
    .Loader-cont {
        height: 100vh;
    }
}