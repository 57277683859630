.FrequestlyAskedQuestion { 
    color: #ffffff; 
    .h1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.125;
    }
    .row {
        .text-cont {
            .heading {
                font-size: 1.3125rem;
                font-weight: 700;
            }
            .texts {
                border-bottom: dotted 1px;
                font-size: .875rem;
            }
        }
        .img-cont {
            height: 300px;
            img {
                object-fit: cover;
                border-radius: 20px;
            }
        }
    }
    @media (min-width: 768px) {
        .h1 {
            font-size: 2.5rem;
        }
        
        .heading {
            font-size: 1.5rem;
        } 
        .texts {
            font-size: 1.5rem;
        }
    }
}