.AboutProduct {
    // background-color: #000000;
    color: #ffffff;
    padding-top: 60px;
    .h1 {
        font-size: 2rem;
        font-weight: bold;
    }
    .h3 {
        font-size: 1rem;
    }
    .texts {
        font-weight: normal;
        line-height: 1.375;
        font-style: normal;
        font-size: .875rem;
    }
    .bluewish-text {
        color: #a9bdf6;
    }
    @media (min-width: 768px) {
        .h1 {
            font-size: 2.5rem;
        }
        .h3 {
            font-size: 1.125rem;
        }
        .texts {
            font-size: 1rem;
        }
    }
}