.Review {
    padding-top: 60px;
    padding-bottom: 50px;
    .h1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.125;
    }
    .stars-container {
        .stars {
            color: #ecbd23;
            font-size: 1rem;
        }
    }
    .texts {
        font-size: 1.25rem;
        font-weight: bold;
    }
    .name {
        font-size: 1rem;
        font-weight: normal;
    }
    @media (min-width: 768px) {
        .h1 {
            font-size: 2.5rem;
        }
    }
        
}